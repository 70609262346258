.layout-wrapper.layout-static .layout-content-wrapper {
  margin-left: 21rem;

  @include maxWidth(1200px) {
    margin-left: 19rem;
  }

  @include maxWidth(1091px) {
    margin-left: 0;
  }
}

.layout-content {
  @include maxWidth(768px) {
    padding: 1rem;
  }

  @include maxWidth(576px) {
    padding: 0.5rem;
  }
}

.layout-wrapper.layout-static .layout-topbar {
  width: calc(100% - 21rem);
  margin-left: 21rem;
  background: transparent;
  border-bottom-color: transparent;
  justify-content: flex-end;

  @include maxWidth(1200px) {
    width: calc(100% - 19rem);
    margin-left: 19rem;
  }

  @include maxWidth(1091px) {
    width: 100%;
    margin-left: 0;
    background: #fff !important;
  }

  .topbar-left {
    display: none;

    @include maxWidth(1091px) {
      display: flex;
    }
  }
}

.layout-menu ul a,
.layout-menu ul .p-link {
  font-size: 16px;

  @include maxWidth(1200px) {
    font-size: 15px;
  }
}

.layout-menu ul ul li a {
  font-size: 15px;
}

.layout-sidebar-darkgray
  .layout-sidebar
  .layout-menu
  .layout-root-menuitem
  > ul
  > li.active-menuitem {
  background: transparent !important;
}

.layout-topbar {
}

.layout-sidebar {
  background: $colorLightBlue !important;
  width: 21rem;

  @include maxWidth(1200px) {
    width: 19rem;
  }

  .logo {
    border-bottom: 1px solid transparent !important;
  }

  .menu-separator {
    border-top: 1px solid rgba($colorMain, 0.4) !important;
  }

  .layout-menuitem-root-text {
    color: rgba($colorMain, 0.6) !important;
  }

  .layout-menu li a,
  .layout-menu li .p-link {
    color: $fontColor !important;
    font-weight: 400;
    // font-size: 1rem;
    border: 1px solid transparent;

    &:hover {
      background: none !important;
      border: 1px solid $colorMain;
    }
  }
}

.content-responsive {
  @include maxWidth(768px) {
    width: 100% !important;
  }
}

.home-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  @include maxWidth(768px) {
    padding-top: 80px;
  }
}

.layout-menu {
  li  {
    a, button {
      transition: $transition;
      border: none!important;
      z-index: 1;
      &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        background: $colorBackground;
        border-radius: 4px;
        opacity: 0;
        transition: $transition;
        pointer-events: none;
        z-index: -1;
      }
      &.active {
        color: $primaryColor!important;
        i:before {
          color: $primaryColor!important;
        }
        font-weight: 700;
        &:before {
          opacity: 1;
        }
      }
    }
  }
}