$gutter: 1rem;

$success-color: #689f38;
$alert-color: #d32f2f;

$invited-color: var(--bluegray-400);
$registered-color: var(--orange-400);
$completed-color: $success-color;
$submitted-color: var(--teal-400);
$partly-completed-color: var(--cyan-400);
$transferred-color: var(--blue-400);
$partly-transferred-color: var(--purple-400);

@import 'node_modules/primeflex/primeflex.scss';

// @import 'prismjs/themes/prism-coy.css';
// @import './AppDemo.scss';

textarea {
  resize: none;
}

.layout-sidebar .logo-image {
  width: 160px;
  height: 38px;
}

.meditex-id-column {
  min-width: 351px;
}

.table-status-badge {
  display: inline-block;
  font-size: 1rem;
  color: #fff;
  margin: 0;
  padding: 0.75rem 1.25rem;
  width: auto;
  border-radius: 4px;

  &.success {
    background-color: $success-color;
  }

  &.alert {
    background-color: $alert-color;
  }

  &.invited {
    background-color: $invited-color;
  }

  &.registered {
    background-color: $registered-color;
  }

  &.completed {
    background-color: $completed-color;
  }

  &.submitted {
    background-color: $submitted-color;
  }

  &.partly-completed {
    background-color: $partly-completed-color;
  }

  &.transferred {
    background-color: $transferred-color;
  }

  &.partly-transferred {
    background-color: $partly-transferred-color;
  }
}

.chat-window {
  border: 1px solid rgb(206, 212, 218);
}

.chat-message {
  word-wrap: break-word;
  max-width: 45%;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);

  &:last-child {
    margin-bottom: 0;
  }
}

.info-item {
  &-image {
    width: 100%;
    height: auto;
    padding: 0;
  }

  &-preview-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all ease 0.3s;

    i {
      transition: all ease 0.3s;
      opacity: 0.8;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &:hover i {
      transform: scale(1.2);
      opacity: 1;
    }
  }
}

.info-dialog {
  width: 942px;
  height: 607px;
}

.questionnaire {
  height: calc(100vh - 20.4rem);
}

.container {
  display: block;
  max-width: 1120px;
  margin: 0 auto;
}

.logo-mini {
  width: 15rem;
}

.button-link {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: var(--blue-600);
  font-weight: 700;
  transition: color 0.3s;

  &:hover {
    color: var(--blue-400);
  }
}

.chat-tabs {
  .p-tabview-nav {
    border: none;
  }

  .p-tabview-panels {
    display: none;
  }
}

@media screen and (max-width: 1091px) {
  .layout-wrapper .layout-topbar .mobile-logo {
    display: block;
    width: 6rem;
    height: 2rem;
  }

  .info-item-preview-overlay {
    height: 98%;
  }

  .info-dialog {
    width: 350px;
    height: 279px;
  }
}

.floating-nav {
  @media screen and (min-width: 1092px) {
    display: none !important;
  }
}
