.questionnaire {
  &-header {
    @include maxWidth(768px) {
      flex-direction: column;
    }
  }

  &-title {
    @include maxWidth(768px) {
      margin-bottom: 20px;
    }
  }
}

.empty-steps {
  max-width: 100%;
}
