.person {
  &-tabs {
    .p-tabview-nav {
      flex-wrap: wrap;
    }
  }

  &-footer {
    div[class*='col'] {
      @include maxWidth(768px) {
        width: 100%;
      }
    }
  }
}
