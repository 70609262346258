@mixin maxWidth($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin minWidth($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content;
  }
}
