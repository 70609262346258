.signature-text {
  font-family: 'Juliette';
  font-size: 60px;
  margin: 30px 0;
}

.locale-selector .p-button.p-button-icon-only {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.p-menu-list .p-menuitem-link {
  padding: 0.75rem 1rem !important;
}

.p-divider {
  &::before {
    border-top: 1px solid $colorMain !important;
  }

  .p-divider-content {
    background-color: $colorBackground !important;
  }
}

.empty-steps .p-steps-number {
  margin-bottom: 25px;
  border-color: $colorMain !important;
  background: transparent !important;
  cursor: pointer;
}

.p-steps-item.p-highlight.p-steps-current .p-steps-number {
  background: $colorMain !important;
  color: $colorBackground !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.terms-container {
  p {
    margin-bottom: 20px;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-center {
    text-align: center;
  }

  strong {
    font-weight: 700;
  }
}

.terms-header {
  @include maxWidth(768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }
}

.terms-header .terms-header-info {
  @include maxWidth(768px) {
    text-align: left !important;
  }
}

.p-toast-detail {
  word-break: break-all;
}

.auth-form-container {
  max-width: 430px;
  padding: 0 15px;

  .grid {
    margin-left: 0;
    margin-right: 0;
  }
}

.auth-main {
  @include maxWidth(576px) {
    display: none;
  }
}

.auth-responsive {
  display: none;
  justify-content: space-between;
  padding: 2rem;

  @include maxWidth(576px) {
    display: flex;
  }

  @include maxWidth(350px) {
    padding: 10px;
  }

  @include maxWidth(279px) {
    flex-direction: column;
  }

  img {
    max-width: 150px;
    height: auto;
  }
}

@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: $colorMain;
  }
  40% {
    stroke: $colorMain;
  }
  66% {
    stroke: $colorMain;
  }
  80%,
  90% {
    stroke: $colorMain;
  }
}

// tables
.p-datatable {
  .p-datatable-table {
    .p-datatable-tbody {
      & > tr {
        & > td {
          padding: .5rem 1rem;
          .p-button, .table-status-badge, p {
            white-space: nowrap;
            width: 100%;
            display: block;
            margin: auto;
            max-width: 24rem;
          }
        }
      }
      .p-button {
        padding: .35rem;
        &:not(.p-button-text) {
          background-color: transparent;
          color: #1d1738;
          &:hover {
            background-color: #1d1738;
            color: #fff;
          }
        }
        span {
          transition: none;
        }
        &.p-button-danger {
          background-color: transparent;
          color: #D32F2F;
          &:hover {
            background-color: #D32F2F;
            color: #fff;
          }
        }
        &.p-button-success {
          background-color: transparent;
          color: #5e8f32;
          &:hover {
            background-color: #5e8f32;
            color: #fff;
          }
        }
        &.p-button-info {
          background-color: transparent;
          color: #0288D1;
          &:hover {
            background-color: #0288D1;
            color: #fff;
          }
        }
      }
      .p-inputswitch {
        margin-top: .5rem;
      }
      .table-status-badge {
        padding: .35rem;
      }
    }
  }
}

// cards
.card {
  box-shadow: none;
  border: 1px solid #dee2e6;
  padding: 1rem;
}

// forms
.form-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 1rem;
}

// inputs
.p-fileupload .p-fileupload-buttonbar.flex .p-button {
  margin-right: 0;
  margin-left: 0.5rem;
  &:first-of-type {
    margin-left: 0;
  }
}
.p-fileupload-filename {
  margin-right: auto;
  margin-left: 0.5rem;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  & + div {
    margin-right: 0.5rem;
  }
}
input.p-inputtext, .p-component.p-dropdown {
  border: 0 solid $primaryColor;
  border-bottom: 1px solid;
  border-radius: 0;
  padding-left: 0;
  background-color: transparent;
  .p-inputtext {
    padding-left: 0;
  }
  &:enabled:focus {
    box-shadow: none;
  }
}
.p-component {
  &:focus, &.p-focus, .p-focus {
    box-shadow: none!important;
  }
  &.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: $primaryColor;
  }
}

//buttons
.p-component {
  &.p-button:enabled:hover, 
  &.p-button:not(button):not(.p-disabled):hover {
    background-color: $hoverBgColor;
  }
}


// pagination
.p-paginator {
  .p-paginator-pages {
    .p-paginator-page.p-link {
      transition: $transition;
    }
  }
}

// info item
.info-item {
  border-bottom: 1px solid $primaryColor;
  height: 100%;
  &-image {
    border-radius: 8px;
    overflow: hidden;
    transition: $transition;
  }
  &-preview-overlay {
    border-radius: 8px;
    overflow: hidden;
    i {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: $colorLightBlue;
      position: absolute;
      &:before {
        position: absolute;
        left: 54%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5rem;
        color: $primaryColor;
      }
    }
  }
  .info-item-preview-overlay:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

// survey creator
.svc-creator {
  border: 1px solid #dee2e6;
}