.dashboard-text {
  max-width: 450px;

  @include maxWidth(1198.98px) {
    max-width: 420px;
  }

  @include maxWidth(1091px) {
    max-width: 450px;
  }
}

.dashboard-container {
  display: flex;

  @include maxWidth(904px) {
    flex-direction: column;
    align-items: center;
  }
}

.dashboard-background {
  height: 100%;
  object-fit: cover;
}
