$colorMain: #1d1738;
$colorOrange: #fca287;
$colorLightBlue: #e8f3f7;
$colorBackground: #f7f5f1;

$fontColor: #343434;

@font-face {
  font-family: 'Axiforma Light'; /*a name to be used later*/
  src: url('./fonts/AxiformaLight/font.woff'); /*URL to font*/
}

@font-face {
  font-family: 'Juliette'; /*a name to be used later*/
  src: url('./fonts/Juliette.otf'); /*URL to font*/
}

$primaryColor: $colorMain;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;

$highlightBg: #e3f2fd;
$highlightTextColor: #495057;

$secondaryColor: #f0dcd3;
$hoverBgColor: #652150;

$fontFamily: 'Axiforma Light';
$buttonPadding: 0.75rem 1.25rem;
$buttonIconOnlyWidth: 3rem;
$inputPadding: 0.75rem 0.75rem;
$inputTextFontSize: 1rem;
$menuitemPadding: 2.75rem 1rem;
$textColor: $fontColor;
$inputListItemHoverBg: $colorBackground;
$inputFocusBorderColor: $colorOrange;
$inputHoverBorderColor: $colorOrange;
$focusShadow: none;
$paginatorElementHoverBg: $colorBackground;
$tableHeaderCellBg: $colorLightBlue;
$menuitemHoverBg: $colorBackground;
$inputListItemFocusShadow: none;

$transition: 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);



body {
  background-color: $colorBackground;
  font-family: $fontFamily;
  overflow-x: hidden;
}

.break-all {
  word-break: break-all;
}

// scrollbar styles

html {
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: .7rem;
  height: .7rem;
  background: $colorLightBlue;
}

*::-webkit-scrollbar-track {
  border-radius: .5rem;
}

*::-webkit-scrollbar-corner,
*::-webkit-resize {
  background: $colorLightBlue;
}

*::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
  outline: .1rem solid rgba(255, 255, 255, 0.16);
  border-radius: .5rem;
}

// routing transition animation

.fadeIn {
  animation: 0.5s fadeIn forwards;
}

.fadeOut {
  // animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(20px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-20px, 0);
    opacity: 0;
  }
}
